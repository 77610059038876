<div class="footer-block">
  <div class="spacer"></div>
  <footer
    [ngClass]="{
      footer: true,
      'nuula-footer': isNuulaUser
    }"
  >
    <div class="footer-text">
      <p>
        Final loan decision may be subject to underwriting by FinWise Bank.
      </p>
      <p>
        The majority of Mulligan Funding, LLC business loans are issued by FinWise Bank, 
        a Utah state-chartered bank. Member FDIC. 
      </p>
      <p>
        &copy; {{ currentYear }} Mulligan Funding. All Rights Reserved.
      </p>
    </div>
  </footer>
</div>
