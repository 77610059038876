import { NgModule } from '@angular/core'
import { Routes, RouterModule, Router } from '@angular/router'
import { FundedComponent } from './funded/funded.component'
import { IneligibleComponent } from './ineligible/ineligible.component'
import { PreApprovalComponent } from './pre-approval/pre-approval.component'
import { NotVerifiedComponent } from './identity-not-verified/identity-not-verified.component'

import { RetryVerificationQuestionsComponent } from './retry-verification-questions/retry-verification-questions.component'
import { BusinessVerificationComponent } from './business-verification/business-verification.component'
import { DeclinedToSignComponent } from './declined-to-sign/declined-to-sign.component'
import { DocCheckoutComponent } from './doc-checkout.component'
import { GenericErrorComponent } from './generic-error/generic-error.component'
import { VerifyEmailComponent } from './verify-email/verify-email.component'
import { EmailVerificationComponent } from './email-verification/email-verification.component'
import { ServerErrorComponent } from './server-error/server-error.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { VerificationLinkExpiredComponent } from './email-verification/verification-link-expired/verification-link-expired.component'
import { UnableToVerifyBusinessComponent } from './unable-to-verify-business/unable-to-verify-business.component'
import { OktaAuthGuard } from '../core/services/guard/app.guard'

// https://github.com/okta/okta-angular#using-a-custom-login-page
export function onAuthRequired(oktaAuth, injector) {
  const router = injector.get(Router)

  // Redirect the user to your custom login page
  router.navigate(['/auth/login'])
}
const docCheckoutRoutes: Routes = [
  {
    path: 'loan/:loanUuid',
    canActivate: [OktaAuthGuard],
    data: {
      // https://www.npmjs.com/package/@okta/okta-angular#using-a-custom-login-page
      // tslint:disable-next-line: object-literal-shorthand
      onAuthRequired,
    },
    component: DocCheckoutComponent,
    children: [
      { path: 'loan-introduction', component: DocCheckoutComponent },
      { path: 'loan-details', component: DocCheckoutComponent },
      { path: 'identity-verification', component: DocCheckoutComponent },
      { path: 'review-submit', component: DocCheckoutComponent },
    ],
  },
  {
    path: 'loan/:loanUuid/start',
    component: BusinessVerificationComponent,
  },
  {
    path: 'loan/:loanUuid/email-verification/:token',
    component: EmailVerificationComponent,
  },
  {
    path: 'loan/:loanUuid/verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'verification-link-expired',
    component: VerificationLinkExpiredComponent,
  },
  {
    path: 'loan/:loanUuid/unable-to-verify-business',
    component: UnableToVerifyBusinessComponent,
  },
  {
    path: 'loan/:loanUuid/declined-to-sign',
    component: DeclinedToSignComponent,
  },
  {
    path: 'error',
    component: GenericErrorComponent,
  },
  {
    path: 'unknownerror',
    component: ServerErrorComponent,
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },

  {
    path: 'loan/:loanUuid/funded',
    component: FundedComponent,
  },
  {
    path: 'loan/:loanUuid/ineligible',
    component: IneligibleComponent,
  },
  {
    path: 'loan/:loanUuid/pre-approval',
    component: PreApprovalComponent,
  },
  {
    path: 'loan/:loanUuid/unable-to-verify-identity',
    component: NotVerifiedComponent,
  },
  {
    path: 'loan/:loanUuid/retry-verification-questions',
    component: RetryVerificationQuestionsComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(docCheckoutRoutes)],
  exports: [RouterModule],
})
export class DocCheckoutRoutingModule {}
