<div class="loan-step-wrap" *ngIf="isShowSidebar">
  <div class="loan-step">
    <div class="loan-step-header" *ngIf="calcNums">
      <app-header [calcNums]="calcNums" [loanStatus]="loanStatus"></app-header>
    </div>

    <div class="sidebar-content-container">
      <div class="sidebar-container">
        <app-sidebar
          [loanStatus]="loanStatus"
          [stepStatus]="stepStatus"
        ></app-sidebar>
      </div>

      <div class="content-container">
        <app-introduction
          [userClaims]="userClaims"
          [calcNums]="calcNums"
          *ngIf="loanStep.loanIntroductionStep && calcNums"
        >
        </app-introduction>

        <app-identity-verification
          [userClaims]="userClaims"
          [calcNums]="calcNums"
          *ngIf="
            loanStep.identityVerificationQuestionsStep ||
            loanStep.retryVerificationQuestions
          "
        >
        </app-identity-verification>

        <app-loan-details
          [userClaims]="userClaims"
          [calcNums]="calcNums"
          *ngIf="loanStep.loanDetailsStep && calcNums"
        >
        </app-loan-details>

        <app-review-submit
          [userClaims]="userClaims"
          [calcNums]="calcNums"
          *ngIf="loanStep.businessReferenceStep"
        >
        </app-review-submit>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
