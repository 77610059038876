<div class="nuula-tile-container">
  <div
    [ngClass]="{
      'tile-container': true,
      'full-screen': isSpinnerVisible() || tokenError
    }"
  >
    <div class="logo-container title-logo-with-line">
      <div>
        <img class="nuula-logo" src="assets/imgs/mf-main-logo.svg" />
      </div>
    </div>
    <div class="spinner-wrapper" *ngIf="isSpinnerVisible()">
      <img class="spinner-icon" src="assets/imgs/uploading-icon.svg" />
    </div>
    <div class="error-wrapper" *ngIf="tokenError">
      <img
        class="static-icon"
        src="assets/imgs/error-generic-illustration.svg"
      />
    </div>
  </div>
  <div class="tile-text-container" *ngIf="!tokenError && !isSpinnerVisible()">
    <div class="tile-title additional">
      Get additional capital for your business
    </div>
    <div class="min-qualification-container">
      <div class="min-qualification">MINIMUM QUALIFICATIONS</div>
      <div>
        <div class="qualifications-list">
          <div class="qualifications-checkmark"></div>
          <div class="qualifications-item">
            At least $120,000 in business annual revenue
          </div>
        </div>
        <div class="qualifications-list">
          <div class="qualifications-checkmark"></div>
          <div class="qualifications-item">
            At least 9 months time in business
          </div>
        </div>
        <div class="qualifications-list">
          <div class="qualifications-checkmark"></div>
          <div class="qualifications-item">Minimum credit score of 600</div>
        </div>
      </div>
    </div>
    <div class="rectangle">
      <div class="restricted-container">
        <div class="restricted">Restricted Industries</div>
        <div class="expand-icon-wrapper" (click)="toggleRestrictedIndustries()">
          <img
            class="expand-icon point-down"
            src="assets/imgs/chevron-down.svg"
            *ngIf="!openSections"
          />
          <img
            class="expand-icon point-up"
            src="assets/imgs/chevron-up.svg"
            *ngIf="openSections"
          />
        </div>
      </div>
      <div class="restricted-industry-list-container" *ngIf="openSections">
        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Adult Entertainment</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Agriculture & Farming</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Air/Sea Freight</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Attorneys & Law Firm</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">
            Auctions, Pawn Shops, Consignment
          </div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">CBD Industry</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">
            Coin & Precious Metal Sales and Trades
          </div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Financial Advisor/Investment</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Financing/Lending</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">
            Financial Transactional Services
          </div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Freight Forwarding</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Health Supplement Manufacturing</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Heavy Machinery Leasing</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Insurance Carriers</div>
        </div>
        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">
            Lotteries, Raffles, Gaming, Gambling
          </div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">
            Mining and Quarries (including coal)
          </div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Non-Profits and/or 501(c)(3)'s</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Oil & Gas Industry</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Real Estate Investments/Sales</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Travel Agencies</div>
        </div>

        <div class="restricted-industry-container">
          <div class="oval"></div>
          <div class="restricted-industry">Vehicle Sales</div>
        </div>
      </div>
    </div>
    <div class="btn-container" (click)="applyNow()">
      <button class="apply-btn">APPLY NOW</button>
    </div>
    <div class="approval-and-funding">
      Approval and funding speed refer to applications submitted during normal
      business hours (Monday-Friday, 9 AM - 5 PM PT.) Most approvals happen
      within a few hours on completed submissions. Delays could occur due to
      unforeseen circumstances or if additional information or verification is
      required. Final loan decision may be subject to underwriting by FinWise Bank.
      The majority of Mulligan Funding, LLC business loans are issued
      by FinWise Bank, a Utah state-chartered bank. Member FDIC.
    </div>
  </div>
</div>
